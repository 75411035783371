export const EMPTY_STRING = "";

export const ITEMS_PER_PAGE = 100;
export const NFT_SEKELTON_ITEMS_NUMBER = 72;

export const customStyles = {
  option: () => ({
    cursor: "pointer",
    padding: "1em 1em",
    fontSize: "0.8em",
    fontFamily: "Montserrat,sans-serif",
    margin: 0,
    zIndex: 50,
    width: "100%",
    color: "white",
    "&:hover": {
      opacity: 0.7,
      color: "white",
      cursor: "pointer",
    },
  }),
  container: (provided: any) => ({
    ...provided,
    fontFamily: "Montserrat,sans-serif",
    fontSize: "14px",
    marginTop: "10px",
    color: "white",
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: "transparent",
    borderRadius: "8px",
  }),
  menuList: (provided: any) => ({
    ...provided,
    backgroundColor: "#0C121D",
    borderRadius: "8px",
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: "transparent",
    color: "white",
    fontWeight: "700",
    padding: "0.5em",
    borderRadius: "8px",
    fontSize: "0.75em",
    display: "flex",
    gap: "0.5em",
    justifyContent: "center",
    alignItems: "center",
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: "white ",
    fontFamily: "Montserrat",
    margin: "0.2em",
  }),
  multiValueRemove: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "transparent",
    padding: "0.1em",
    borderRadius: "10px",
    border: "1px solid white",
    "&:hover": {
      backgroundColor: "#040e24",
      color: "white",
    },
  }),
  input: (provided: any) => ({
    ...provided,
    color: "white",
    fontSize: "1.25em",
    marginLeft: "1em",
    height: "2.5em",
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    color: "white",
    padding: "0.5em",
    overflow: "hidden",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: "1.2em",
    fontWeight: "100",
    fontFamily: "Montserrat, sans-serif",
    marginLeft: "1em",
    color: "white",
  }),
  control: (provided: any) => ({
    ...provided,
    borderRadius: "10px",
    fontSize: "13px",
    cursor: "pointer",
    background: "rgba(255, 255, 255, 0.03)",
    border: "none",
    color: "white",
    fontFamily: "Montserrat",
    boxShadow: "none",
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    padding: "3px",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "white",
  }),
  indicatorSeparator(base: any, props: any) {
    return {
      display: "none",
    };
  },
};
