import { RestCodes } from "../common/common.enums";

const BASE_URL = "https://api-unq-chain-traits-jiezb.ondigitalocean.app";

class HttpError {
  constructor(public response: Response) {}
}

export function post<T = any>(
  path: string,
  data: Record<string, any>
): Promise<T> {
  return makeRequest("POST", path, data);
}

export function put<T = any>(
  path: string,
  data: Record<string, any>
): Promise<T> {
  return makeRequest("PUT", path, data);
}

export function patch<T = any>(
  path: string,
  data: Record<string, any>
): Promise<T> {
  return makeRequest("PATCH", path, data);
}

export function deleteReq<T = any>(
  path: string,
  data: Record<string, any>
): Promise<T> {
  return makeRequest("DELETE", path, data);
}

export function get<T = any>(path: string): Promise<T> {
  return makeRequest("GET", path);
}

type HttpMethod = "POST" | "GET" | "PUT" | "PATCH" | "DELETE";

export async function makeRequest(
  method: HttpMethod,
  path?: string,
  data?: Record<string, any>,
  customBaseUrl?: string
) {
  let response;
  const headers: HeadersInit = {
    "Content-Type": "application/json",
    Accept: "application/json, */*",
  };

  const requestInit: RequestInit = {
    method,
    headers,
  };

  if (data) {
    requestInit.body = JSON.stringify(data);
  }

  response = await fetch(`${customBaseUrl ?? BASE_URL}/${path}`, requestInit);

  if (response.status >= RestCodes.BadRequest) {
    throw new HttpError(response);
  }

  if (method === "DELETE") {
    return response;
  }
  return response.json();
}
