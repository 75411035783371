import React, { FC, useState } from "react";
import {
  INftData,
  ITraitConfig,
  ITraitConfigValue,
} from "../../../common/common.interface";
import Select from "react-select";
import { customStyles } from "../../../common/common.constants";

export const SelectTrait: FC<{
  traitKey: string;
  traitValue: string;
  traitConfig: ITraitConfig | undefined;
  updateOnChainTraitHandler: (value: string, key: string) => void;
}> = ({ traitKey, traitValue, traitConfig, updateOnChainTraitHandler }) => {
  const [selectedTrait, setSelectedTrait] = useState(
    traitConfig?.availableTraits
      .find((availableTrait) => availableTrait.name === traitKey)
      ?.values.find((valueItem) => valueItem.value === traitValue)
  );
  return (
    <div>
      <Select
        className="select"
        styles={customStyles}
        value={selectedTrait}
        options={
          traitConfig?.availableTraits.find((item) => item.name === traitKey)
            ?.values
        }
        isSearchable={false}
        formatOptionLabel={(option: ITraitConfigValue) => {
          return <p>{option.value}</p>;
        }}
        onChange={(option: ITraitConfigValue) => {
          updateOnChainTraitHandler(option.value, traitKey);
          setSelectedTrait(option);
        }}
      />
    </div>
  );
};
