import { get } from "./http";

export const getCollectionSlugFromMint = async (tokenMint: string) => {
  try {
    const response = await get(`collection-slug/${tokenMint}`);
    return response.slug;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getCollectionTraitsFromSlug = async (slug: string) => {
  try {
    const response = await get(`traits/${slug}`);
    const collectionInfoFromSlug = response.traits;

    const traitsMap = new Map<string, string[]>();
    if (collectionInfoFromSlug)
      Object.keys(collectionInfoFromSlug).forEach((item) =>
        traitsMap.set(item, Object.keys(collectionInfoFromSlug[item]))
      );
    return traitsMap;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getAllNftsFromCollectionWithTraits = async (slug: string) => {
  try {
    const response = await get(`nfts/${slug}`);
    return response.nfts;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
