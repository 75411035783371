//TODO@milica: check if we need this file
import { booleanFilter, WalletSigner } from "@solana/spl-governance";
import { WalletContextState } from "@solana/wallet-adapter-react";
import {
  Commitment,
  Connection,
  SignatureStatus,
  SimulatedTransactionResponse,
  Transaction,
  TransactionSignature,
  PublicKey,
  TransactionMessage,
  VersionedTransaction,
  AddressLookupTableAccount,
  AddressLookupTableProgram,
} from "@solana/web3.js";
import { SequenceType, TransactionState } from "../../common/common.enums";
import { IInstructionSet } from "../../common/common.interface";
import useTransactionStore, {
  ITransactionInfo,
} from "../../store/transactionStore";
import { RPC_CONNECTION } from "../utilities";
import { DEFAULT_TIMEOUT, getUnixTs } from "./sendTransaction";

export const sleep = (ttl: number) =>
  new Promise((resolve) => setTimeout(() => resolve(true), ttl));

export class TransactionError extends Error {
  public txid: string;
  constructor(message: string, txid: string) {
    super(message);
    this.txid = txid;
  }
}

/**
 * Awaits for confirmation of transaction signature
 *
 * @param txid
 * @param timeout
 * @param connection
 * @param commitment
 * @param queryStatus
 * @returns
 */
async function awaitTransactionSignatureConfirmation(
  txid: TransactionSignature,
  timeout: number,
  connection: Connection,
  commitment: Commitment = "confirmed",
  queryStatus = false
) {
  let done = false;
  let status: SignatureStatus | null = {
    slot: 0,
    confirmations: 0,
    err: null,
  };
  let subId = 0;
  await new Promise((resolve, reject) => {
    const fn = async () => {
      setTimeout(() => {
        if (done) {
          return;
        }
        done = true;
        reject({ timeout: true });
      }, timeout);
      try {
        subId = connection.onSignature(
          txid,
          (result, context) => {
            done = true;
            status = {
              err: result.err,
              slot: context.slot,
              confirmations: 0,
            };
            if (result.err) {
              console.log("Rejected via websocket", result.err);
              reject(result.err);
            } else {
              console.log("Resolved via websocket", result);
              resolve(result);
            }
          },
          commitment
        );
      } catch (e) {
        done = true;
        console.error("WS error in setup", txid, e);
      }
      while (!done && queryStatus) {
        // eslint-disable-next-line no-loop-func
        const fn = async () => {
          try {
            const signatureStatuses = await connection.getSignatureStatuses([
              txid,
            ]);
            status = signatureStatuses && signatureStatuses.value[0];
            if (!done) {
              if (!status) {
                console.log("REST null result for", txid, status);
              } else if (status.err) {
                console.log("REST error for", txid, status);
                done = true;
                reject(status.err);
              } else if (!status.confirmations) {
                console.log("REST no confirmations for", txid, status);
              } else {
                console.log("REST confirmation for", txid, status);
                if (status.confirmationStatus === "confirmed") {
                  done = true;
                  resolve(status);
                }
              }
            }
          } catch (e) {
            if (!done) {
              console.log("REST connection error: txid", txid, e);
            }
            throw e;
          }
        };
        await fn();
        await sleep(2000);
      }
    };
    fn();
  })
    .catch((err) => {
      if (err.timeout && status) {
        status.err = { timeout: true };
      }

      connection.removeSignatureListener(subId);
      throw err;
    })
    .then((_) => {
      connection.removeSignatureListener(subId);
    });
  done = true;
  return status;
}

/**
 * Sends signed transaction
 * @param param0
 * @returns
 */
export async function sendSignedTransaction({
  signedTransaction,
  connection,
  timeout = DEFAULT_TIMEOUT,
  errorMessage,
}: {
  signedTransaction: Transaction;
  connection: Connection;
  sendingMessage?: string;
  sentMessage?: string;
  successMessage?: string;
  errorMessage?: string;
  timeout?: number;
}): Promise<{ txid: string; slot: number }> {
  const rawTransaction = signedTransaction.serialize();
  const startTime = getUnixTs();
  let slot = 0;
  const txid: TransactionSignature = await connection.sendRawTransaction(
    rawTransaction,
    {
      skipPreflight: true,
    }
  );

  console.log("Started awaiting confirmation for", txid);

  let done = false;
  (async () => {
    while (!done && getUnixTs() - startTime < timeout) {
      connection.sendRawTransaction(rawTransaction, {
        skipPreflight: true,
      });
      await sleep(500);
    }
  })();
  try {
    const confirmation = await awaitTransactionSignatureConfirmation(
      txid,
      timeout,
      connection,
      "confirmed",
      true
    );

    if (confirmation.err) {
      console.error(confirmation.err);
      throw new Error("Transaction failed: Custom instruction error");
    }

    slot = confirmation?.slot || 0;
  } catch (error) {
    if (error instanceof Object && error.hasOwnProperty("timeout")) {
      throw new Error("Timed out awaiting confirmation on transaction");
    }
    let simulateResult: SimulatedTransactionResponse | null = null;
    try {
      //TODO@milica@guta: check new version with VersionedTransaction
      simulateResult = (
        await RPC_CONNECTION.simulateTransaction(signedTransaction)
      ).value;
    } catch (e) {
      //
    }

    throw new TransactionError("Transaction failed", txid);
  } finally {
    done = true;
  }

  console.log("Latency", txid, getUnixTs() - startTime);
  return { txid, slot };
}

/**
 * Sends multiple transactions at once
 *
 * @param connection
 * @param wallet
 * @param instructionSet
 * @param isClubCreation
 * @param clubInfo
 * @param sequenceType
 * @param commitment
 * @param successCallback
 * @param failCallback
 * @param block
 * @returns
 */
export const sendTransactions = async (
  connection: Connection,
  wallet: WalletSigner,
  instructionSet: IInstructionSet[],
  sequenceType: SequenceType = SequenceType.Sequential,
  callBackTransactionNumber?: number,
  isRepeating?: boolean,
  commitment: Commitment = "confirmed",
  successCallback: (txid: string, ind: number) => void = (_txid, _ind) => null,
  failCallback: (reason: string, ind: number) => boolean = (_txid, _ind) =>
    false,
  block?: {
    blockhash: string;
    lastValidBlockHeight: number;
  },
  customTxModalTitle?: string
) => {
  const {
    startProcessing,
    updateCurrentTransaction,
    updateProcessedTransactions,
    transactions,
    closeTransactionProcess,
    setCustomTitle,
  } = useTransactionStore.getState();

  try {
    let indexOfMagicEdenApiBuyCall = -1;
    let index;
    if (!wallet.publicKey) throw new Error("Wallet not connected!");
    const accountInfo = await RPC_CONNECTION.getParsedAccountInfo(
      wallet.publicKey
    );
    if (!accountInfo.value) throw new Error("You do not have enough SOL.");
    const unsignedTxns: Transaction[] = [];
    let signedTxns: Transaction[] = [];
    if (!block) {
      block = await connection.getLatestBlockhash(commitment);
    }

    const transactionsForStore: ITransactionInfo[] = [];
    let transactionCount = callBackTransactionNumber ?? 0;

    for (let i = 0; i < instructionSet.length; i++) {
      const instructions = instructionSet[i];
      if (instructions.makeMagicEdenaApiCall) {
        indexOfMagicEdenApiBuyCall = i;
      }

      if (instructions.instructions.length === 0) {
        transactionsForStore.push({
          number: i + 1,
          transactionState: TransactionState.Pending,
          txid: null,
          description: instructionSet[i].description,
        });
        continue;
      }

      const transaction = new Transaction({
        feePayer: wallet.publicKey,
        recentBlockhash: block.blockhash,
      });

      instructions.instructions.forEach((instruction) =>
        transaction.add(instruction)
      );

      if (instructions.partialSigner) {
        transaction.partialSign(instructions.partialSigner!);
      }

      unsignedTxns.push(transaction);

      if (!callBackTransactionNumber) {
        transactionsForStore.push({
          number: i + 1,
          transactionState: TransactionState.Pending,
          txid: null,
          description: instructionSet[i].description,
        });
      } else {
        updateCurrentTransaction({
          number: transactionCount + 1,
          transactionState: TransactionState.Pending,
          txid: null,
          description: instructions.description,
        });
      }
      transactionCount++;
    }
    !callBackTransactionNumber &&
      customTxModalTitle &&
      setCustomTitle(customTxModalTitle);
    !callBackTransactionNumber && startProcessing(transactionsForStore);
    try {
      signedTxns = await wallet.signAllTransactions(unsignedTxns);
    } catch (error) {
      closeTransactionProcess();
      console.log(error);
      throw error;
    }

    const pendingTxns: { txid: string; slot: number }[] = [];

    const breakEarlyObject = { breakEarly: false };
    transactionCount = callBackTransactionNumber ?? 0;
    for (let i = 0; i < signedTxns.length; i++) {
      try {
        const processedTx = {
          number: transactionCount + 1,
          transactionState: TransactionState.Loading,
          txid: null,
          description: instructionSet[i].description,
        };

        if (signedTxns.length === 0) {
          updateCurrentTransaction(processedTx);
        }

        updateCurrentTransaction(processedTx);

        updateProcessedTransactions(processedTx);

        const signedTxnPromise = await sendSignedTransaction({
          connection,
          signedTransaction: signedTxns[i],
        });

        const successfullTx = {
          number: transactionCount + 1,
          transactionState: TransactionState.Succeeded,
          txid: signedTxnPromise.txid,
          description: instructionSet[i].description,
        };
        updateCurrentTransaction(successfullTx);

        pendingTxns.push(signedTxnPromise);
        transactionCount++;
      } catch (error: any) {
        console.log(error);
        const instructionsSetNew = instructionSet.slice(
          i,
          instructionSet.length
        );

        updateCurrentTransaction(
          {
            number: transactionCount + 1,
            transactionState: TransactionState.Failed,
            txid: error?.txid,
            description: instructionSet[i].description,
          },
          async () => {
            try {
              await sendTransactions(
                connection,
                wallet,
                instructionsSetNew,
                sequenceType,
                i,
                true
              );
            } catch (error) {
              console.log(error);
            }
          }
        );
        throw error;
      }

      // eslint-disable-next-line eqeqeq
    }

    // eslint-disable-next-line eqeqeq
    // if (sequenceType != SequenceType.Parallel) {
    //   await Promise.all(pendingTxns);
    // }
  } catch (error: any) {
    console.log(error);

    throw error;
  }
};

//WORKAROUND FOR SENDING MULTIPLE VERSIONED TRANSACTIONS WITH ADDRESS LOOKUP TABLE
export const sendVersionedTransactionsWithAddressLookupTable = async (
  connection: Connection,
  wallet: WalletContextState,
  instructionSet: IInstructionSet[],
  sequenceType: SequenceType = SequenceType.Sequential,
  callBackTransactionNumber?: number,
  isRepeating?: boolean,
  commitment: Commitment = "confirmed",
  successCallback: (txid: string, ind: number) => void = (_txid, _ind) => null,
  failCallback: (reason: string, ind: number) => boolean = (_txid, _ind) =>
    false,
  block?: {
    blockhash: string;
    lastValidBlockHeight: number;
  }
) => {
  const {
    startProcessing,
    updateCurrentTransaction,
    updateProcessedTransactions,
    transactions,
    closeTransactionProcess,
  } = useTransactionStore.getState();

  try {
    let indexOfMagicEdenApiBuyCall = -1;
    let index;
    if (!wallet.publicKey) throw new Error("Wallet not connected!");
    const accountInfo = await RPC_CONNECTION.getParsedAccountInfo(
      wallet.publicKey
    );
    if (!accountInfo.value) throw new Error("You do not have enough SOL.");
    const unsignedTxns: VersionedTransaction[] = [];
    let signedTxns: VersionedTransaction[] = [];
    if (!block) {
      block = await connection.getLatestBlockhash(commitment);
    }

    const transactionsForStore: ITransactionInfo[] = [];
    let transactionCount = callBackTransactionNumber ?? 0;

    for (let i = 0; i < instructionSet.length; i++) {
      const instructions = instructionSet[i];
      if (instructions.makeMagicEdenaApiCall) {
        indexOfMagicEdenApiBuyCall = i;
      }

      if (
        !instructions.shouldSendSeparate &&
        instructions.instructions.length === 0
      ) {
        transactionsForStore.push({
          number: i + 1,
          transactionState: TransactionState.Pending,
          txid: null,
          description: instructionSet[i].description,
        });
        continue;
      }

      let versionedTx: VersionedTransaction | undefined = undefined;

      if (
        instructions.addressLookupTableKey &&
        !instructions.shouldSendSeparate
      ) {
        // console.log(instructions.addressLookupTableKey.toString());
        // const lookupTable = await RPC_CONNECTION.getAddressLookupTable(
        //   instructions.addressLookupTableKey!
        // );

        // console.log(lookupTable, "LOOKUP TABLE");
        // const versionMsg2 = new TransactionMessage({
        //   instructions: instructions.instructions,
        //   payerKey: wallet.publicKey!,
        //   recentBlockhash: (await RPC_CONNECTION.getLatestBlockhash())
        //     .blockhash,
        // }).compileToV0Message([lookupTable.value!]);

        // AddressLookupTableProgram.

        console.log(instructions.accounts, "ACCOUNTS");

        // const extendIx = AddressLookupTableProgram.extendLookupTable({
        //   addresses: instructions.accounts!,
        //   authority: wallet.publicKey!,
        //   lookupTable: instructions.addressLookupTableKey!,
        //   payer: wallet.publicKey!,
        // });

        // instructions.instructions.push(extendIx);

        console.log(instructionSet[i - 1]);

        await sleep(5000);
        const lookupTable = await RPC_CONNECTION.getAddressLookupTable(
          instructionSet[i - 1].addressLookupTableKey!
        );

        console.log(lookupTable, "LOOKUP TABLE");
        const versionMsg = new TransactionMessage({
          instructions: instructions.instructions,
          payerKey: wallet.publicKey!,
          recentBlockhash: (await RPC_CONNECTION.getLatestBlockhash())
            .blockhash,
        }).compileToV0Message([
          new AddressLookupTableAccount({
            key: instructionSet[i - 1].addressLookupTableKey!,
            state: {
              addresses: instructionSet[i - 1].accounts!,
              deactivationSlot: BigInt(0),
              lastExtendedSlot: instructionSet[i - 1].slot!,
              lastExtendedSlotStartIndex: 0,
            },
          }),
        ]);

        versionedTx = new VersionedTransaction(versionMsg);
      } else {
        const slot = await RPC_CONNECTION.getSlot();
        const [addressLookupTable, addressLookupTableKey] =
          AddressLookupTableProgram.createLookupTable({
            authority: wallet.publicKey!,
            payer: wallet.publicKey!,
            recentSlot: slot,
          });

        const extendIx = AddressLookupTableProgram.extendLookupTable({
          addresses: instructions.accounts!,
          authority: wallet.publicKey!,
          lookupTable: addressLookupTableKey,
          payer: wallet.publicKey!,
        });

        instructions.instructions.push(addressLookupTable);
        instructions.instructions.push(extendIx);
        instructions.addressLookupTableKey = addressLookupTableKey;
        instructions.slot = slot;

        console.log(instructions.instructions, "IXS");

        const versionMsg2 = new TransactionMessage({
          instructions: instructions.instructions,
          payerKey: wallet.publicKey!,
          recentBlockhash: (await RPC_CONNECTION.getLatestBlockhash())
            .blockhash,
        }).compileToV0Message();

        versionedTx = new VersionedTransaction(versionMsg2);
      }

      // const transaction = new Transaction({
      //   feePayer: wallet.publicKey,
      //   recentBlockhash: block.blockhash,
      // });

      // instructions.instructions.forEach((instruction) =>
      //   transaction.add(instruction)
      // );

      // if (instructions.partialSigner) {
      //   transaction.partialSign(instructions.partialSigner!);
      // }

      if (instructions.shouldSendSeparate) {
        const signedTxns = await wallet.signTransaction!(versionedTx);
        await sendSignedVersionedTransaction({
          connection,
          signedTransaction: signedTxns,
        });

        const lookupTable = await RPC_CONNECTION.getAddressLookupTable(
          instructions.addressLookupTableKey!
        );
        console.log(lookupTable, "LOOKUP TABLE 222");
      } else {
        console.log("UNSIGHED");
        unsignedTxns.push(versionedTx);

        if (!callBackTransactionNumber) {
          transactionsForStore.push({
            number: transactionCount + 1,
            transactionState: TransactionState.Pending,
            txid: null,
            description: instructionSet[i].description,
          });
        } else {
          updateCurrentTransaction({
            number: transactionCount + 1,
            transactionState: TransactionState.Pending,
            txid: null,
            description: instructions.description,
          });
        }
        transactionCount++;
      }
    }
    !callBackTransactionNumber && startProcessing(transactionsForStore);
    try {
      signedTxns = await wallet.signAllTransactions!(unsignedTxns);
    } catch (error) {
      closeTransactionProcess();
      console.log(error);
      throw error;
    }

    await sleep(2000);

    const pendingTxns: { txid: string; slot: number }[] = [];

    const breakEarlyObject = { breakEarly: false };
    transactionCount = callBackTransactionNumber ?? 0;
    for (let i = 0; i < signedTxns.length; i++) {
      try {
        const processedTx = {
          number: transactionCount + 1,
          transactionState: TransactionState.Loading,
          txid: null,
          description: transactionsForStore[transactionCount].description,
        };

        if (signedTxns.length === 0) {
          updateCurrentTransaction(processedTx);
        }

        updateCurrentTransaction(processedTx);

        updateProcessedTransactions(processedTx);

        const signedTxnPromise = await sendSignedVersionedTransaction({
          connection,
          signedTransaction: signedTxns[i],
        });

        const successfullTx = {
          number: transactionCount + 1,
          transactionState: TransactionState.Succeeded,
          txid: signedTxnPromise.txid,
          description: transactionsForStore[transactionCount + 1].description,
        };
        updateCurrentTransaction(successfullTx);

        pendingTxns.push(signedTxnPromise);
        transactionCount++;
      } catch (error: any) {
        console.log(error);
        const instructionsSetNew = instructionSet.slice(
          i,
          instructionSet.length
        );

        updateCurrentTransaction(
          {
            number: transactionCount + 1,
            transactionState: TransactionState.Failed,
            txid: error?.txid,
            description: "",
          },
          async () => {
            try {
              await sendVersionedTransactionsWithAddressLookupTable(
                connection,
                wallet,
                instructionsSetNew,
                sequenceType,
                i,
                true
              );
            } catch (error) {
              console.log(error);
            }
          }
        );
        throw error;
      }

      // eslint-disable-next-line eqeqeq
    }

    // eslint-disable-next-line eqeqeq
    // if (sequenceType != SequenceType.Parallel) {
    //   await Promise.all(pendingTxns);
    // }
  } catch (error: any) {
    console.log(error);

    throw error;
  }
};

export async function sendSignedVersionedTransaction({
  signedTransaction,
  connection,
  timeout = DEFAULT_TIMEOUT,
  errorMessage,
}: {
  signedTransaction: VersionedTransaction;
  connection: Connection;
  sendingMessage?: string;
  sentMessage?: string;
  successMessage?: string;
  errorMessage?: string;
  timeout?: number;
}): Promise<{ txid: string; slot: number }> {
  const rawTransaction = signedTransaction.serialize();
  const startTime = getUnixTs();
  let slot = 0;
  const txid: TransactionSignature = await connection.sendRawTransaction(
    rawTransaction,
    {
      skipPreflight: true,
    }
  );

  console.log("Started awaiting confirmation for", txid);

  let done = false;
  (async () => {
    while (!done && getUnixTs() - startTime < timeout) {
      connection.sendRawTransaction(rawTransaction, {
        skipPreflight: true,
      });
      await sleep(500);
    }
  })();
  try {
    const confirmation = await awaitTransactionSignatureConfirmation(
      txid,
      timeout,
      connection,
      "confirmed",
      true
    );

    if (confirmation.err) {
      console.error(confirmation.err);
      throw new Error("Transaction failed: Custom instruction error");
    }

    slot = confirmation?.slot || 0;
  } catch (error) {
    if (error instanceof Object && error.hasOwnProperty("timeout")) {
      throw new Error("Timed out awaiting confirmation on transaction");
    }
    let simulateResult: SimulatedTransactionResponse | null = null;
    try {
      //TODO@milica@guta: check new version with VersionedTransaction
      simulateResult = (
        await RPC_CONNECTION.simulateTransaction(signedTransaction)
      ).value;
    } catch (e) {
      //
    }

    throw new TransactionError("Transaction failed", txid);
  } finally {
    done = true;
  }

  console.log("Latency", txid, getUnixTs() - startTime);
  return { txid, slot };
}
