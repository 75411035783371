import "react-loading-skeleton/dist/skeleton.css";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import { WalletWrapper } from "./components/WalletWrapper/WalletWrapper";
import Navbar from "./components/Navbar/Navbar";
import HomePage from "./pages/HomePage/HomePage";
import BulkTransactionsModal from "./components/BulkTransactionsModal/BulkTransactionsModal";

function App() {
  return (
    <WalletWrapper>
      <Navbar />
      <HomePage />
      <BulkTransactionsModal />
      <NotificationContainer />
    </WalletWrapper>
  );
}

export default App;
