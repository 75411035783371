import React, { FC, useState } from "react";
import { EditTraitAction } from "../../../common/common.enums";
import { ITraitConfigValue } from "../../../common/common.interface";
import { TraitAction } from "../../../generated";
import AddNewTrait from "./AddNewTrait/AddNewTrait";
import "./ConfigButtons.scss";
const ConfigButtons: FC<{
  handleClick: (action: EditTraitAction) => void;
  traitName: string;
  traitsCount: number;
  saveTraits: (traits: ITraitConfigValue[], traitName: string) => void;
}> = ({ handleClick, traitName, saveTraits, traitsCount }) => {
  const [isModalVisible, toggleIsModalVisible] = useState(false);
  return (
    <div className="config-buttons">
      {isModalVisible && (
        <AddNewTrait
          traitsCount={traitsCount}
          closeModal={() => toggleIsModalVisible(false)}
          traitName={traitName}
          saveTraitValues={(traits: ITraitConfigValue[]) =>
            saveTraits(traits, traitName)
          }
        />
      )}
      {/* <button
        onClick={() => toggleIsModalVisible(true)}
        className="config-buttons__button config-buttons__button--add"
      >
        Add new trait
      </button> */}
      <button
        onClick={() => handleClick(EditTraitAction.Revoke)}
        className="config-buttons__button config-buttons__button--activate"
      >
        Activate trait
      </button>
      <button
        onClick={() => handleClick(EditTraitAction.Remove)}
        className="config-buttons__button config-buttons__button--remove"
      >
        Remove trait
      </button>
    </div>
  );
};

export default ConfigButtons;
