import React, { FC } from "react";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import "./ConnectWallet.scss";

const ConnectWallet: FC = () => {
  return (
    <WalletModalProvider>
      <div className="connect-wallet__wrapper">
        <div className="connect-wallet">
          <h3 className="connect-wallet__title">Please connect your wallet</h3>
          <WalletMultiButton className="connect-wallet__wallet-button-home" />
        </div>
      </div>
    </WalletModalProvider>
  );
};

export default ConnectWallet;
