/**
 * This code was GENERATED using the solita package.
 * Please DO NOT EDIT THIS FILE, instead rerun solita to update it or write a wrapper to add functionality.
 *
 * See: https://github.com/metaplex-foundation/solita
 */

import * as beet from '@metaplex-foundation/beet'
import { TraitAction, traitActionBeet } from './TraitAction'
export type CreateTraitConfigArgs = {
  name: string
  values: string[]
  action: TraitAction
}

/**
 * @category userTypes
 * @category generated
 */
export const createTraitConfigArgsBeet =
  new beet.FixableBeetArgsStruct<CreateTraitConfigArgs>(
    [
      ['name', beet.utf8String],
      ['values', beet.array(beet.utf8String)],
      ['action', traitActionBeet],
    ],
    'CreateTraitConfigArgs'
  )
