import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";

export interface SkeletonItemProps {
  width?: number;
  height: number;
  customColor?: string;
  isCircle?: boolean;
  borderRadius?: number;
}

const SkeletonItem: FC<SkeletonItemProps> = ({
  width,
  height,
  customColor,
  isCircle,
  borderRadius,
}) => {
  return (
    <Skeleton
      borderRadius={borderRadius ?? 8}
      baseColor={`${customColor ?? "var(--primary-color)"}`}
      style={{ marginBottom: "1em" }}
      width={width ?? "100%"}
      height={height}
      enableAnimation={true}
      highlightColor="#0C121D"
      circle={isCircle ?? false}
    />
  );
};

export default SkeletonItem;
