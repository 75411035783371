import { FC } from "react";
import "./MainButton.scss";

const MainButton: FC<{
  notSelected?: boolean;
  onClick: () => void;
  title: string;
}> = ({ notSelected, onClick, title }) => {
  return (
    <button
      className={`main-button ${notSelected && "main-button--not-selected"}`}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default MainButton;
