/**
 * This code was GENERATED using the solita package.
 * Please DO NOT EDIT THIS FILE, instead rerun solita to update it or write a wrapper to add functionality.
 *
 * See: https://github.com/metaplex-foundation/solita
 */

import * as beet from '@metaplex-foundation/beet'
export type CreateTraitArgs = {
  name: string
  value: string
}

/**
 * @category userTypes
 * @category generated
 */
export const createTraitArgsBeet =
  new beet.FixableBeetArgsStruct<CreateTraitArgs>(
    [
      ['name', beet.utf8String],
      ['value', beet.utf8String],
    ],
    'CreateTraitArgs'
  )
