import React, { FC, Fragment, useState } from "react";
import { NftsActiveTab } from "../../../common/common.enums";
import { INftData, ITraitConfig } from "../../../common/common.interface";
import NftItemModal from "../NftItemModal/NftItemModal";

const NftItem: FC<{
  item: INftData;
  activeTab: NftsActiveTab;
  traitConfig: ITraitConfig | undefined;
}> = ({ item, activeTab, traitConfig }) => {
  const [isModalActive, toggleModal] = useState(false);
  return (
    <Fragment>
      {isModalActive && (
        <NftItemModal
          activeTab={activeTab}
          closeModal={() => toggleModal(false)}
          nft={item}
          traitConfig={traitConfig}
        />
      )}
      <div
        className="collection-nfts__nft-item"
        onClick={() => toggleModal(true)}
      >
        <img
          src={item.imageUri}
          alt="Nft image"
          className="collection-nfts__nft-img"
        />
        <p className="collection-nfts__nft-name">
          {item.name !== "" ? item.name : item.slug}
        </p>
        <div className="collection-nfts__nft-attributes"></div>
      </div>
    </Fragment>
  );
};

export default NftItem;
