/**
 * This code was GENERATED using the solita package.
 * Please DO NOT EDIT THIS FILE, instead rerun solita to update it or write a wrapper to add functionality.
 *
 * See: https://github.com/metaplex-foundation/solita
 */

import * as beet from '@metaplex-foundation/beet'
import * as web3 from '@solana/web3.js'
import {
  CreateTraitConfigArgs,
  createTraitConfigArgsBeet,
} from '../types/CreateTraitConfigArgs'

/**
 * @category Instructions
 * @category CreateTraitConfig
 * @category generated
 */
export type CreateTraitConfigInstructionArgs = {
  data: CreateTraitConfigArgs[]
}
/**
 * @category Instructions
 * @category CreateTraitConfig
 * @category generated
 */
export const CreateTraitConfigStruct = new beet.FixableBeetArgsStruct<
  CreateTraitConfigInstructionArgs & {
    instructionDiscriminator: number
  }
>(
  [
    ['instructionDiscriminator', beet.u8],
    ['data', beet.array(createTraitConfigArgsBeet)],
  ],
  'CreateTraitConfigInstructionArgs'
)
/**
 * Accounts required by the _CreateTraitConfig_ instruction
 *
 * @property [] collection Collection or first creator of collection
 * @property [_writable_] traitConfigAccount Account used for storing trait config on-chain
 * @property [**signer**] updateAuthority Signer of transaction(update authority of collection)
 * @property [] collectionMetadata Metadata account of collection NFT or NFT from collection
 * @category Instructions
 * @category CreateTraitConfig
 * @category generated
 */
export type CreateTraitConfigInstructionAccounts = {
  collection: web3.PublicKey
  traitConfigAccount: web3.PublicKey
  updateAuthority: web3.PublicKey
  collectionMetadata: web3.PublicKey
  systemProgram?: web3.PublicKey
}

export const createTraitConfigInstructionDiscriminator = 0

/**
 * Creates a _CreateTraitConfig_ instruction.
 *
 * @param accounts that will be accessed while the instruction is processed
 * @param args to provide as instruction data to the program
 *
 * @category Instructions
 * @category CreateTraitConfig
 * @category generated
 */
export function createCreateTraitConfigInstruction(
  accounts: CreateTraitConfigInstructionAccounts,
  args: CreateTraitConfigInstructionArgs,
  programId = new web3.PublicKey('EVYWVskBg3xFwL3Px5FvGY3iC3kR4n4Mo9AA8kpTk1JB')
) {
  const [data] = CreateTraitConfigStruct.serialize({
    instructionDiscriminator: createTraitConfigInstructionDiscriminator,
    ...args,
  })
  const keys: web3.AccountMeta[] = [
    {
      pubkey: accounts.collection,
      isWritable: false,
      isSigner: false,
    },
    {
      pubkey: accounts.traitConfigAccount,
      isWritable: true,
      isSigner: false,
    },
    {
      pubkey: accounts.updateAuthority,
      isWritable: false,
      isSigner: true,
    },
    {
      pubkey: accounts.collectionMetadata,
      isWritable: false,
      isSigner: false,
    },
    {
      pubkey: accounts.systemProgram ?? web3.SystemProgram.programId,
      isWritable: false,
      isSigner: false,
    },
  ]

  const ix = new web3.TransactionInstruction({
    programId,
    keys,
    data,
  })
  return ix
}
