import React, { FC } from "react";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import "./Navbar.scss";
import logo from "../../assets/uniqueLogo.svg";

const Navbar: FC = () => {
  return (
    <WalletModalProvider>
      <div className="navbar">
        <img src={logo} className="navbar__logo" alt="Unique logo" />
        <WalletMultiButton className="navbar__wallet-button" />
      </div>
    </WalletModalProvider>
  );
};

export default Navbar;
