import { AnchorWallet } from "@solana/wallet-adapter-react";
import { Connection, PublicKey } from "@solana/web3.js";
// import { Metaplex } from "@metaplex-foundation/js";
import { Buffer } from "buffer";
window.Buffer = Buffer;

export const RPC_CONNECTION = new Connection(
  "https://fragrant-shy-emerald.solana-devnet.quiknode.pro/3b335e3257cda2382e0b76d23e158c61b2d90902/",
  "processed"
);

export const MAINNET_RPC_CONNECTION = new Connection(
  "https://dry-cosmological-pool.solana-mainnet.quiknode.pro/e2650ac4ff2395af0262c730666ef3b1064037ce/",
  "confirmed"
);

export const METADATA_PROGRAM = new PublicKey(
  "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
);

// export const metaplex = Metaplex.make(MAINNET_RPC_CONNECTION);
