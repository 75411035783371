import create from "zustand";
import { TransactionState } from "../common/common.enums";

export interface ITransactionInfo {
  txid: string | null;
  number: number;
  transactionState: TransactionState;
  description: string;
}

interface ITransactionStore {
  isProcessing: boolean;
  transactions: ITransactionInfo[];
  addTransactions: (transaction: ITransactionInfo[]) => void;
  processedTransactions: ITransactionInfo[];
  retryCallback: (() => Promise<void>) | null;
  updateCurrentTransaction: (
    transaction: ITransactionInfo,
    retryCallback?: () => Promise<void>
  ) => void;
  closeTransactionProcess: () => void;
  startProcessing: (transactions: ITransactionInfo[]) => void;
  updateProcessedTransactions: (transaction: ITransactionInfo) => void;
  warningDescription?: string;
  customTitle?: string;
  setWarningDescription: (description: string) => void;
  setCustomTitle: (title: string) => void;
}

const defaultState = {
  isProcessing: false,
  transactions: [],
  processedTransactions: [],
  retryCallback: null,
  nftData: undefined,
  customTitle: undefined,
  warningDescription: undefined,
};

const useTransactionStore = create<ITransactionStore>((set, get) => ({
  ...defaultState,
  startProcessing: (transactions: ITransactionInfo[]) => {
    set({
      isProcessing: true,
      transactions: transactions,
    });
  },
  closeTransactionProcess: () => {
    set({
      ...defaultState,
    });
  },
  updateCurrentTransaction: (
    transaction: ITransactionInfo,
    retryCallback?: () => Promise<void>
  ) => {
    const transactions = [...get().transactions];
    const txIndex = transactions.findIndex(
      (item) => item.number === transaction.number
    );
    transactions[txIndex] = {
      number: transactions[txIndex].number,
      transactionState: transaction.transactionState,
      txid: transaction.txid,
      description: transaction.description,
    };

    set({
      transactions: transactions,
      retryCallback: retryCallback ?? null,
    });
  },
  updateProcessedTransactions: (transaction: ITransactionInfo) => {
    const processedTx = get().processedTransactions;
    set({
      processedTransactions: [...processedTx, transaction],
    });
  },
  addTransactions: (transactions: ITransactionInfo[]) =>
    set(() => ({ transactions: [...get().transactions, ...transactions] })),
  setWarningDescription: (description: string) =>
    set(() => ({ warningDescription: description })),
  setCustomTitle: (title: string) => set(() => ({ customTitle: title })),
}));

export default useTransactionStore;
