import { Metadata } from "@metaplex-foundation/mpl-token-metadata";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import {
  ComputeBudgetProgram,
  PublicKey,
  TransactionInstruction,
  SYSVAR_INSTRUCTIONS_PUBKEY,
  AccountMeta,
} from "@solana/web3.js";
import {
  IInstructionSet,
  INftData,
  ITraitConfig,
} from "../common/common.interface";
import { chunk } from "loadsh";
import { sendTransactions } from "./transactions/sendTransactions";
import { MAINNET_RPC_CONNECTION, RPC_CONNECTION } from "./utilities";
import {
  CreateTraitConfigArgs,
  PROGRAM_ID,
  CreateTraitConfigStruct,
  createTraitConfigInstructionDiscriminator,
  createCreateTraitConfigInstruction,
  TraitConfig,
  TraitData,
  CreateTraitArgs,
  createCreateTraitInstruction,
  AvailableTrait,
} from "../generated";

export const createTraitConfig = async (
  wallet: AnchorWallet,
  args: CreateTraitConfigArgs[],
  collection: PublicKey,
  updateAuthoirty: PublicKey,
  nftMint?: PublicKey
) => {
  try {
    const instructionSet: IInstructionSet[] = [];
    const [traitConfigAddress] = PublicKey.findProgramAddressSync(
      [Buffer.from("trait-config"), collection.toBuffer()],
      PROGRAM_ID
    );

    //Use nftMint in case if collection is first creator
    const collectionMetadata = await Metadata.findByMint(
      MAINNET_RPC_CONNECTION,
      nftMint ?? collection
    );

    //BETTER FOR SMALLER AMOUNT OF TXS BUT NOT OPTIMAZED FOR FRONT
    // let dataLen = 0;
    // let endSlice = args.length;
    // let startSlice = 0;
    // const chunkedArr = [];
    // while (startSlice < args.length) {
    //   const [data] = CreateTraitConfigStruct.serialize({
    //     instructionDiscriminator: createTraitConfigInstructionDiscriminator,
    //     data: args.slice(startSlice, endSlice),
    //   });

    //   dataLen = data.byteLength;

    //   if (dataLen < 800) {
    //     chunkedArr.push(args.slice(startSlice, endSlice));
    //     startSlice = endSlice;
    //     endSlice = args.length;
    //   } else {
    //     endSlice--;
    //   }
    // }
    // const chunkedArr = chunk(args, 1);

    for (const arr of args) {
      const [data] = CreateTraitConfigStruct.serialize({
        instructionDiscriminator: createTraitConfigInstructionDiscriminator,
        data: [arr],
      });

      const dataLen = data.byteLength;
      const requestComputeUnitsTx = ComputeBudgetProgram.setComputeUnitLimit({
        units: 1400000,
      });

      const requestHeapBudgetTx = ComputeBudgetProgram.requestHeapFrame({
        bytes: 256 * 1024,
      });
      if (dataLen > 500) {
        const array = Array.from(arr.values);
        let configArgs: [number, AvailableTrait][][] = chunk(
          array,
          array.length / (dataLen / 500 + 1)
        );
        for (const arg of configArgs) {
          const mapp: Map<number, AvailableTrait> = new Map();
          arg.forEach((item) => {
            mapp.set(item[0], item[1]);
          });

          const configAr: CreateTraitConfigArgs = {
            name: arr.name,
            values: arr.values,
            action: arr.action,
          };
          const ix = createCreateTraitConfigInstruction(
            {
              collection: collection,
              collectionMetadata: collectionMetadata.pubkey,
              traitConfigAccount: traitConfigAddress,
              updateAuthority: updateAuthoirty,
            },
            {
              data: [configAr],
            },
            PROGRAM_ID
          );

          instructionSet.push({
            description: "Update traits",
            instructions: [requestComputeUnitsTx, requestHeapBudgetTx, ix],
          });
        }
      } else {
        const ix = createCreateTraitConfigInstruction(
          {
            collection: collection,
            collectionMetadata: collectionMetadata.pubkey,
            traitConfigAccount: traitConfigAddress,
            updateAuthority: updateAuthoirty,
          },
          {
            data: [arr],
          },
          PROGRAM_ID
        );

        instructionSet.push({
          description: "Update traits",
          instructions: [requestComputeUnitsTx, requestHeapBudgetTx, ix],
        });
      }
      // const ix = createCreateTraitConfigInstruction(
      //   {
      //     collection: collection,
      //     collectionMetadata: collectionMetadata.pubkey,
      //     traitConfigAccount: traitConfigAddress,
      //     updateAuthority: updateAuthoirty,
      //   },
      //   {
      //     data: [arr],
      //   },
      //   PROGRAM_ID
      // );

      // instructionSet.push({
      //   description: "Add traits",
      //   instructions: [requestComputeUnitsTx, ix],
      // });
    }
    await sendTransactions(RPC_CONNECTION, wallet, instructionSet);
  } catch (error) {
    console.log(error);
  }
};

export const getTraitConfigInfo = async (collection: PublicKey) => {
  try {
    const [traitConfigAddress, traitConfigAddressBump] =
      PublicKey.findProgramAddressSync(
        [Buffer.from("trait-config"), collection.toBuffer()],
        PROGRAM_ID
      );
    return await TraitConfig.fromAccountAddress(
      RPC_CONNECTION,
      traitConfigAddress
    );
  } catch (error) {
    throw error;
  }
};

export const getTraitDatasForCollection = async (collection: PublicKey) => {
  try {
    const [traitConfigAddress, traitConfigAddressBump] =
      PublicKey.findProgramAddressSync(
        [Buffer.from("trait-config"), collection.toBuffer()],
        PROGRAM_ID
      );
    const test = TraitData.gpaBuilder();
    test.addFilter("traitConfig", traitConfigAddress);
    const nfts = await test.run(RPC_CONNECTION);
    return nfts;
  } catch (error) {
    console.log(error);
  }
};

export const createTraitDatas = async (
  nfts: INftData[],
  wallet: AnchorWallet,
  collection: PublicKey,
  traitConfig: ITraitConfig
) => {
  try {
    const chunkedNfts: INftData[][] = chunk(nfts, 3);
    const instructionSet: IInstructionSet[] = [];
    const [traitConfigAddress, _traitConfigAddressBump] =
      PublicKey.findProgramAddressSync(
        [Buffer.from("trait-config"), collection.toBuffer()],
        PROGRAM_ID
      );
    for (const chunkedNftsArray of chunkedNfts) {
      const instructions: TransactionInstruction[] = [];
      const remainingAccounts: AccountMeta[] = [];
      const chunkedArguments: CreateTraitArgs[][] = [];
      for (const nft of chunkedNftsArray) {
        const args: CreateTraitArgs[] = [];
        nft.offChaintraits.forEach((itemValue, itemKey) => {
          const availableTrait = traitConfig.availableTraits.find(
            (availableTrait) => availableTrait.name == itemKey
          );
          const availableTraitValue = availableTrait?.values.find(
            (value) => value.value === itemValue
          );
          if (availableTrait && availableTraitValue) {
            args.push({
              name: availableTrait.name,
              value: availableTraitValue.value,
            });
          }
        });

        const [traitData] = PublicKey.findProgramAddressSync(
          [
            Buffer.from("trait-data"),
            nft.address.toBuffer(),
            traitConfigAddress.toBuffer(),
          ],
          PROGRAM_ID
        );
        chunkedArguments.push(args);

        remainingAccounts.push({
          isSigner: false,
          isWritable: true,
          pubkey: await Metadata.getPDA(nft.address),
        });
        remainingAccounts.push({
          isSigner: false,
          isWritable: true,
          pubkey: traitData,
        });
        remainingAccounts.push({
          isSigner: false,
          isWritable: true,
          pubkey: nft.address,
        });
      }
      const ix = createCreateTraitInstruction(
        {
          payer: wallet.publicKey!,
          traitConfigAccount: traitConfigAddress,
          instructionSysvar: SYSVAR_INSTRUCTIONS_PUBKEY,
        },
        {
          data: chunkedArguments,
        }
      );
      instructions.push(ix);

      const requestComputeUnitsTx = ComputeBudgetProgram.requestUnits({
        additionalFee: 0,
        units: 1400000,
      });

      remainingAccounts.forEach((item) => {
        ix.keys.push(item);
      });
      instructions.push(requestComputeUnitsTx);

      instructionSet.push({
        description: "Create trait data",
        instructions: instructions,
        shouldSendSeparate: false,
      });
    }
    await sendTransactions(
      RPC_CONNECTION,
      wallet,
      instructionSet,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      `Creating trait data for a ${nfts.length} nfts${
        nfts.length === 100 ? ", to continue go to the next page" : ""
      }`
    );
  } catch (error) {
    console.log(error);
  }
};

export const updateTraitData = async (
  nft: INftData,
  updatedTraits: Map<string, string>,
  wallet: AnchorWallet
) => {
  try {
    const remainingAccounts: AccountMeta[] = [];
    const instructionSet: IInstructionSet[] = [];
    const [traitData] = PublicKey.findProgramAddressSync(
      [
        Buffer.from("trait-data"),
        nft.address.toBuffer(),
        nft.traigConfigAddress!.toBuffer(),
      ],
      PROGRAM_ID
    );

    const args: CreateTraitArgs[] = [];

    updatedTraits.forEach((value, key) =>
      args.push({
        name: key,
        value: value,
      })
    );
    const ix = createCreateTraitInstruction(
      {
        payer: wallet.publicKey!,
        traitConfigAccount: nft.traigConfigAddress!,
        instructionSysvar: SYSVAR_INSTRUCTIONS_PUBKEY,
      },
      {
        data: [args],
      }
    );

    remainingAccounts.push({
      isSigner: false,
      isWritable: true,
      pubkey: await Metadata.getPDA(nft.address),
    });
    remainingAccounts.push({
      isSigner: false,
      isWritable: true,
      pubkey: traitData,
    });
    remainingAccounts.push({
      isSigner: false,
      isWritable: true,
      pubkey: nft.address,
    });
    remainingAccounts.forEach((item) => {
      ix.keys.push(item);
    });

    instructionSet.push({
      description: "Updating traits",
      instructions: [ix],
    });

    await sendTransactions(RPC_CONNECTION, wallet, instructionSet);
  } catch (error) {}
};
