/**
 * This code was GENERATED using the solita package.
 * Please DO NOT EDIT THIS FILE, instead rerun solita to update it or write a wrapper to add functionality.
 *
 * See: https://github.com/metaplex-foundation/solita
 */

type ErrorWithCode = Error & { code: number }
type MaybeErrorWithCode = ErrorWithCode | null | undefined

const createErrorFromCodeLookup: Map<number, () => ErrorWithCode> = new Map()
const createErrorFromNameLookup: Map<string, () => ErrorWithCode> = new Map()

/**
 * InvalidCollection: 'Metadata is not related to provided collection'
 *
 * @category Errors
 * @category generated
 */
export class InvalidCollectionError extends Error {
  readonly code: number = 0x0
  readonly name: string = 'InvalidCollection'
  constructor() {
    super('Metadata is not related to provided collection')
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, InvalidCollectionError)
    }
  }
}

createErrorFromCodeLookup.set(0x0, () => new InvalidCollectionError())
createErrorFromNameLookup.set(
  'InvalidCollection',
  () => new InvalidCollectionError()
)

/**
 * NotUpdateAuthority: 'Signer is not update authority of collection'
 *
 * @category Errors
 * @category generated
 */
export class NotUpdateAuthorityError extends Error {
  readonly code: number = 0x1
  readonly name: string = 'NotUpdateAuthority'
  constructor() {
    super('Signer is not update authority of collection')
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, NotUpdateAuthorityError)
    }
  }
}

createErrorFromCodeLookup.set(0x1, () => new NotUpdateAuthorityError())
createErrorFromNameLookup.set(
  'NotUpdateAuthority',
  () => new NotUpdateAuthorityError()
)

/**
 * TraitConfigNotInitialized: 'Trait config not initialized'
 *
 * @category Errors
 * @category generated
 */
export class TraitConfigNotInitializedError extends Error {
  readonly code: number = 0x2
  readonly name: string = 'TraitConfigNotInitialized'
  constructor() {
    super('Trait config not initialized')
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, TraitConfigNotInitializedError)
    }
  }
}

createErrorFromCodeLookup.set(0x2, () => new TraitConfigNotInitializedError())
createErrorFromNameLookup.set(
  'TraitConfigNotInitialized',
  () => new TraitConfigNotInitializedError()
)

/**
 * WrongAuthorityToCreateTrait: 'You don't have authority to store traits on chain!'
 *
 * @category Errors
 * @category generated
 */
export class WrongAuthorityToCreateTraitError extends Error {
  readonly code: number = 0x3
  readonly name: string = 'WrongAuthorityToCreateTrait'
  constructor() {
    super("You don't have authority to store traits on chain!")
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, WrongAuthorityToCreateTraitError)
    }
  }
}

createErrorFromCodeLookup.set(0x3, () => new WrongAuthorityToCreateTraitError())
createErrorFromNameLookup.set(
  'WrongAuthorityToCreateTrait',
  () => new WrongAuthorityToCreateTraitError()
)

/**
 * TraitDoesNotExist: 'Trait does not exist in trait config'
 *
 * @category Errors
 * @category generated
 */
export class TraitDoesNotExistError extends Error {
  readonly code: number = 0x4
  readonly name: string = 'TraitDoesNotExist'
  constructor() {
    super('Trait does not exist in trait config')
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, TraitDoesNotExistError)
    }
  }
}

createErrorFromCodeLookup.set(0x4, () => new TraitDoesNotExistError())
createErrorFromNameLookup.set(
  'TraitDoesNotExist',
  () => new TraitDoesNotExistError()
)

/**
 * InvalidAccountSeeds: 'Invalid account seeds'
 *
 * @category Errors
 * @category generated
 */
export class InvalidAccountSeedsError extends Error {
  readonly code: number = 0x5
  readonly name: string = 'InvalidAccountSeeds'
  constructor() {
    super('Invalid account seeds')
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, InvalidAccountSeedsError)
    }
  }
}

createErrorFromCodeLookup.set(0x5, () => new InvalidAccountSeedsError())
createErrorFromNameLookup.set(
  'InvalidAccountSeeds',
  () => new InvalidAccountSeedsError()
)

/**
 * Attempts to resolve a custom program error from the provided error code.
 * @category Errors
 * @category generated
 */
export function errorFromCode(code: number): MaybeErrorWithCode {
  const createError = createErrorFromCodeLookup.get(code)
  return createError != null ? createError() : null
}

/**
 * Attempts to resolve a custom program error from the provided error name, i.e. 'Unauthorized'.
 * @category Errors
 * @category generated
 */
export function errorFromName(name: string): MaybeErrorWithCode {
  const createError = createErrorFromNameLookup.get(name)
  return createError != null ? createError() : null
}
