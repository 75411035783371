import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { FC, useEffect, useState } from "react";
import { getAllNftsFromCollectionWithTraits } from "../../../api/chain-traits-api";
import { ITEMS_PER_PAGE } from "../../../common/common.constants";
import { NftsActiveTab } from "../../../common/common.enums";
import {
  INftData,
  ITensorNftInfo,
  ITraitConfig,
} from "../../../common/common.interface";
import MainButton from "../../../components/MainButton/MainButton";
import SkeletonItem from "../../../components/SkeletonItem/SkeletonItem";
import { TraitData } from "../../../generated";
import {
  createTraitDatas,
  getTraitDatasForCollection,
} from "../../../solana/traits";
import { generateSkeletonArrays } from "../../../utiilities/helpers";
import NftList from "../NftList/NftList";
import "./CollectionNfts.scss";

const CollectionNfts: FC<{
  collectionSlug: string | undefined;
  collection: string | undefined;
  traitConfig: ITraitConfig | undefined;
}> = ({ collection, collectionSlug, traitConfig }) => {
  const [activeTab, setActiveTab] = useState(NftsActiveTab.OffChain);
  const [offChainNfts, updateOffChainNfts] = useState<INftData[]>();
  const [onChainNfts, updateOnChainNfts] = useState<INftData[]>();
  const [loadingNfts, toggleLoadingNfts] = useState(false);
  const [currentItems, setCurrentItems] = useState<INftData[]>();
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [tensorNfts, setTensorNfts] = useState<ITensorNftInfo[]>();

  const wallet = useAnchorWallet();

  useEffect(() => {
    if (!collection || !wallet?.publicKey) {
      updateOffChainNfts(undefined);
      updateOnChainNfts(undefined);
      setCurrentItems(undefined);
      setTensorNfts(undefined);
    }
    collectionSlug && getCollectionNfts(false);
  }, [collectionSlug, wallet?.publicKey]);

  useEffect(() => {
    setPageCount(0);
    setItemOffset(0);
  }, [activeTab]);

  useEffect(() => {
    if (offChainNfts && onChainNfts) {
      const items =
        activeTab === NftsActiveTab.OffChain ? offChainNfts : onChainNfts;
      const endOffset = itemOffset + ITEMS_PER_PAGE;
      setCurrentItems(items.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(items.length / ITEMS_PER_PAGE));
    }
  }, [itemOffset, offChainNfts, onChainNfts, activeTab]);

  const handlePageClick = (event: { selected: number }) => {
    if (offChainNfts && onChainNfts) {
      const items =
        activeTab === NftsActiveTab.OffChain ? offChainNfts : onChainNfts;
      const newOffset = (event.selected * ITEMS_PER_PAGE) % items.length;
      setItemOffset(newOffset);
    }
  };

  const getCollectionNfts = async (isUpdating: boolean) => {
    try {
      toggleLoadingNfts(true);
      if (collectionSlug && collection) {
        let offChainNfts = tensorNfts;
        if (!tensorNfts || !isUpdating) {
          const nfts = await getAllNftsFromCollectionWithTraits(collectionSlug);
          offChainNfts = nfts;
          setTensorNfts(nfts);
        }

        const offChainNftsData: INftData[] = [];
        const onChainNftsData: INftData[] = [];

        const traitDatas = await getTraitDatasForCollection(
          new PublicKey(collection)
        );

        const serializedTraitDatas: Map<string, TraitData> = new Map();
        if (traitDatas) {
          for (const traitData of traitDatas) {
            const serializedTrait = TraitData.fromAccountInfo(
              traitData.account
            );
            serializedTraitDatas.set(
              serializedTrait[0].nftMint.toString(),
              serializedTrait[0]
            );
          }
        }

        offChainNfts?.forEach(
          (item: {
            imageUri: string;
            name: string;
            attributes: any[];
            slug: string;
            onchainId: string;
          }) => {
            const traitDataConfig = serializedTraitDatas.get(item.onchainId);
            const offChainMap: Map<string, string> = new Map();
            item.attributes &&
              item.attributes.forEach((item) => {
                offChainMap.set(item.trait_type, item.value);
              });
            const nftInfo: INftData = {
              imageUri: item.imageUri,
              name: item.name,
              offChaintraits: offChainMap,
              onChainTraits: traitDataConfig?.traits,
              slug: item.slug,
              address: new PublicKey(item.onchainId),
              traigConfigAddress: traitDataConfig?.traitConfig,
            };

            nftInfo.traigConfigAddress
              ? onChainNftsData.push(nftInfo)
              : offChainNftsData.push(nftInfo);
          }
        );

        updateOffChainNfts(offChainNftsData);
        updateOnChainNfts(onChainNftsData);
      }
      toggleLoadingNfts(false);
    } catch (error) {
      console.log(error);
      toggleLoadingNfts(false);
    }
  };

  const createTraitDataForNfts = async (nfts: INftData[]) => {
    try {
      if (wallet && collection && traitConfig) {
        await createTraitDatas(
          nfts,
          wallet,
          new PublicKey(collection),
          traitConfig
        );
        await getCollectionNfts(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="collection-nfts">
      <div className="collection-nfts__header">
        <div>
          {(loadingNfts || currentItems) && (
            <h3 className="collection-nfts__nfts-title">Collection NFTS</h3>
          )}
          {!loadingNfts ? (
            currentItems && (
              <div>
                <button
                  className={`collection-nfts__tab-button ${
                    activeTab === NftsActiveTab.OffChain &&
                    "collection-nfts__tab-button--active"
                  }`}
                  onClick={() => setActiveTab(NftsActiveTab.OffChain)}
                >
                  Nfts with off chain traits
                </button>
                <button
                  className={`collection-nfts__tab-button ${
                    activeTab === NftsActiveTab.OnChain &&
                    "collection-nfts__tab-button--active"
                  }`}
                  onClick={() => setActiveTab(NftsActiveTab.OnChain)}
                >
                  Nfts with on chain traits
                </button>
              </div>
            )
          ) : (
            <SkeletonItem height={30} width={370} />
          )}
        </div>
        {activeTab === NftsActiveTab.OffChain &&
          traitConfig &&
          currentItems &&
          currentItems?.length > 0 && (
            <MainButton
              title="Create trait data"
              onClick={() => createTraitDataForNfts(currentItems)}
            />
          )}
      </div>

      {!loadingNfts ? (
        currentItems && (
          <NftList
            currentItems={currentItems}
            handlePageClick={handlePageClick}
            pageCount={pageCount}
            activeTab={activeTab}
            traitConfig={traitConfig}
          />
        )
      ) : (
        <div className="collection-nfts__skeleton-list">
          {generateSkeletonArrays(72).map((item) => (
            <SkeletonItem height={45} key={item} />
          ))}
        </div>
      )}
    </div>
  );
};

export default CollectionNfts;
