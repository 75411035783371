import React, { FC, Fragment, useEffect, useState } from "react";
import { INftData, ITraitConfig } from "../../../common/common.interface";
import ReactPaginate from "react-paginate";
import "./NftList.scss";
import { NftsActiveTab } from "../../../common/common.enums";
import NftItem from "../NftItem/NftItem";

const NftList: FC<{
  handlePageClick: (event: { selected: number }) => void;
  pageCount: number;
  currentItems: INftData[];
  activeTab: NftsActiveTab;
  traitConfig: ITraitConfig | undefined;
}> = ({ currentItems, handlePageClick, pageCount, activeTab, traitConfig }) => {
  return (
    <Fragment>
      <div className="nft-list">
        {currentItems.length > 0 && (
          <ReactPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={10}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
          />
        )}
        <div className="nft-list__list">
          {currentItems &&
            currentItems.map((item) => (
              <NftItem
                key={item.address.toString()}
                item={item}
                activeTab={activeTab}
                traitConfig={traitConfig}
              />
            ))}
        </div>
      </div>
    </Fragment>
  );
};

export default NftList;
