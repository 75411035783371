import React, { FC, useEffect, useState } from "react";
import "./EditTraitValue.scss";
import removeImg from "../../../../assets/removeImg.svg";
import { EMPTY_STRING } from "../../../../common/common.constants";
const EditTraitValue: FC<{
  isActive: boolean;
  value: string;
  isNew: boolean;
  isSelected: boolean;
  typeName: string;
  valueName: string;
  onItemSelected: (
    typeName: string,
    valueName: string,
    shouSelect: boolean
  ) => void;
}> = ({
  isActive,
  value,
  isNew,
  isSelected,
  onItemSelected,
  typeName,
  valueName,
}) => {
  const [className, setClassName] = useState(EMPTY_STRING);
  const [buttonClassName, setButtonClassName] = useState(EMPTY_STRING);

  useEffect(() => {
    if (isSelected) {
      setClassName("edit-trait-value edit-trait-value__selected");
      setButtonClassName(
        "edit-trait-value__value edit-trait-value__value--selected"
      );
    } else {
      setClassName(
        `edit-trait-value ${
          isActive
            ? "edit-trait-value edit-trait-value--active"
            : isNew
            ? "edit-trait-value edit-trait-value--new"
            : "edit-trait-value edit-trait-value--non-active"
        }`
      );
      setButtonClassName(
        `${
          !isActive
            ? "edit-trait-value__value edit-trait-value__value--non-active "
            : isNew
            ? "edit-trait-value__value edit-trait-value__value--new"
            : "edit-trait-value__value"
        }`
      );
    }
  }, [isSelected]);

  return (
    <div
      onClick={() => onItemSelected(typeName, valueName, !!!isSelected)}
      className={className}
    >
      <p className={buttonClassName}>{value}</p>
    </div>
  );
};

export default EditTraitValue;
