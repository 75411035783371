export enum TransactionState {
  Pending,
  Loading,
  Succeeded,
  Failed,
}
export enum SequenceType {
  Sequential,
  Parallel,
  StopOnFailure,
}
export enum NftsActiveTab {
  OffChain,
  OnChain,
}

export enum EditTraitAction {
  Add,
  Remove,
  Revoke,
}
export enum RestCodes {
  Ok = 200,
  BadRequest = 400,
  NotFound = 404,
  Conflict = 409,
}
