import React, { FC, useMemo, useState } from "react";
import Modal from "../../../../components/Modal/Modal";
import overviewAddIcon from "../../../../assets/overviewAddIcon.svg";
import "./AddNewTrait.scss";
import closeIcon from "../../../../assets/removeImg.svg";
import MainButton from "../../../../components/MainButton/MainButton";
import { ITraitConfigValue } from "../../../../common/common.interface";
import { EMPTY_STRING } from "../../../../common/common.constants";
const AddNewTrait: FC<{
  closeModal: () => void;
  traitName: string;
  traitsCount: number;
  saveTraitValues: (traits: ITraitConfigValue[]) => void;
}> = ({ closeModal, traitName, saveTraitValues, traitsCount }) => {
  const [addedTraits, setAddedTraits] = useState<string[]>([EMPTY_STRING]);
  const [error, setError] = useState<string>();

  const changeTraitValue = (value: string, index: number) => {
    setError(undefined);
    const storedTraits = [...addedTraits];
    storedTraits[index] = value;
    setAddedTraits(storedTraits);
  };

  const removeTrait = (index: number) => {
    const storedTraits = [...addedTraits];
    storedTraits.splice(index, 1);
    setAddedTraits(storedTraits);
  };

  const renderInputFields = useMemo(() => {
    return addedTraits.map((at, index) => {
      return (
        <div className="add-new-trait__input">
          <img
            onClick={() => removeTrait(index)}
            src={closeIcon}
            alt="closeIcon"
          />
          <input
            onChange={(e) => changeTraitValue(e.target.value, index)}
            className="home-page__collection-input"
            value={at}
            placeholder="Trait value"
          />
        </div>
      );
    });
  }, [addedTraits]);

  const addNewTrait = () => {
    const storedTraits = [...addedTraits];
    storedTraits.push(EMPTY_STRING);
    setAddedTraits(storedTraits);
  };

  const saveTraits = () => {
    if (addedTraits.findIndex((ad) => ad == EMPTY_STRING) >= 0) {
      setError("Can't save empty traits");
      return;
    }

    const traits: ITraitConfigValue[] = addedTraits.map((ad, index) => {
      return {
        isActive: true,
        id: traitsCount + index,
        value: ad,
        isNew: true,
      };
    });
    closeModal();
    saveTraitValues(traits);
  };

  return (
    <Modal closeModal={closeModal}>
      <p className="add-new-trait__title">
        Add new trait - <span className="add-new-trait__span">{traitName}</span>
      </p>
      <div className="add-new-trait">{renderInputFields}</div>
      {error && <p className="add-new-trait__error">{error}</p>}
      <button onClick={addNewTrait} className="add-new-trait__button">
        Add trait <img src={overviewAddIcon} />
      </button>

      <div className="add-new-trait__buttons">
        <button onClick={closeModal} className="add-new-trait__cancel">
          Cancel
        </button>
        <MainButton title="Save traits" onClick={() => saveTraits()} />
      </div>
    </Modal>
  );
};

export default AddNewTrait;
