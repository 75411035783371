import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { FC, useState } from "react";
import { NftsActiveTab } from "../../../common/common.enums";
import { INftData, ITraitConfig } from "../../../common/common.interface";
import MainButton from "../../../components/MainButton/MainButton";
import Modal from "../../../components/Modal/Modal";
import { updateTraitData } from "../../../solana/traits";
import { SelectTrait } from "../SelectTrait/SelectTrait";
import "./NftItemModal.scss";

const NftItemModal: FC<{
  closeModal: () => void;
  nft: INftData;
  activeTab: NftsActiveTab;
  traitConfig: ITraitConfig | undefined;
}> = ({ closeModal, nft, activeTab, traitConfig }) => {
  const [isEditMode, toggleEditMode] = useState(false);
  const [onChainTraits, updateOnChainTraits] = useState(nft.onChainTraits);
  const wallet = useAnchorWallet();

  const updateOnChainTraitHandler = (traitValue: string, traitKey: string) => {
    const newTraits = onChainTraits;
    newTraits?.set(traitKey, traitValue);
    updateOnChainTraits(newTraits);
  };

  const updateTraits = async () => {
    try {
      {
        wallet &&
          onChainTraits &&
          (await updateTraitData(nft, onChainTraits, wallet));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal closeModal={closeModal}>
      <div className="nft-item-modal">
        <div className="nft-item-modal__nft-info">
          <img src={nft.imageUri} alt="Nft image" />
          <div className="nft-item-modal__basic-info">
            <h3>{nft.name}</h3>
            <div className="nft-item-modal__basic-info-card">
              <p className="nft-item-modal__basic-info-label">Address</p>
              <p className="nft-item-modal__basic-info-value">
                {nft.address.toString()}
              </p>
            </div>
            <div className="nft-item-modal__basic-info-card">
              <p className="nft-item-modal__basic-info-label">Collection</p>
              <p className="nft-item-modal__basic-info-value">{nft.slug}</p>
            </div>
          </div>
        </div>

        {activeTab === NftsActiveTab.OffChain ? (
          <div className="nft-item-modal__traits-info">
            <h3>Off-chain traits</h3>
            <div className="nft-item-modal__traits">
              {Array.from(nft.offChaintraits).map((item) => (
                <div className="nft-item-modal__trait-info" key={item[0]}>
                  <p className="nft-item-modal__trait-info-key">{item[0]}</p>
                  <p className="nft-item-modal__trait-info-value">{item[1]}</p>
                </div>
              ))}
            </div>
          </div>
        ) : (
          nft.onChainTraits && (
            <div className="nft-item-modal__traits-info">
              <div className="nft-item-modal__traits-info-header">
                <h3>On-chain traits</h3>
                <button onClick={() => toggleEditMode(!isEditMode)}>
                  {isEditMode ? "View traits" : "Edit traits"}
                </button>
              </div>
              <div className="nft-item-modal__traits">
                {Array.from(nft.onChainTraits).map((item) => (
                  <div className="nft-item-modal__trait-info" key={item[0]}>
                    <p className="nft-item-modal__trait-info-key">{item[0]}</p>
                    {isEditMode ? (
                      <SelectTrait
                        traitConfig={traitConfig}
                        traitKey={item[0]}
                        traitValue={item[1]}
                        updateOnChainTraitHandler={updateOnChainTraitHandler}
                      />
                    ) : (
                      <p className="nft-item-modal__trait-info-value">
                        {item[1]}
                      </p>
                    )}
                  </div>
                ))}
              </div>
              {isEditMode && (
                <MainButton onClick={updateTraits} title="Update traits" />
              )}
            </div>
          )
        )}
      </div>
    </Modal>
  );
};

export default NftItemModal;
