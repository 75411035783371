import React, { FC } from "react";
import MainButton from "../../../components/MainButton/MainButton";
import Modal from "../../../components/Modal/Modal";
import "./CreateConfigModal.scss";

const CreateConfigModal: FC<{
  closeModal: () => void;
  traits: Map<string, string[]>;
  createConfig: () => void;
}> = ({ closeModal, traits, createConfig }) => {
  return (
    <Modal closeModal={closeModal}>
      <div className="create-config">
        <div className="create-config__header">
          <h3 className="create-config__title">Collection traits</h3>
          <MainButton
            onClick={createConfig}
            title={"Create on chain trait config"}
          />
        </div>
        {Array.from(traits).map((item) => (
          <div className="create-config__item" key={item[0]}>
            <p className="create-config__item-key">{item[0]}</p>
            <div className="create-config__item-value-map">
              {item[1].map((item) => (
                <p className="create-config__item-value">{item}</p>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default CreateConfigModal;
