import { FC, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import "./EditConfigModal.scss";

import {
  ITraitConfig,
  ITraitConfigValue,
} from "../../../common/common.interface";
import EditTraitValue from "./EditTraitValue/EditTraitValue";
import ConfigButtons from "./ConfigButtons";
import { EditTraitAction } from "../../../common/common.enums";
import MainButton from "../../../components/MainButton/MainButton";
import { createTraitConfig } from "../../../solana/traits";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { CreateTraitConfigArgs, TraitAction } from "../../../generated";
import { PublicKey } from "@solana/web3.js";
import { chunk } from "lodash";

const EditConfigModal: FC<{
  closeModal: () => void;
  traits: ITraitConfig;
  nftMint?: PublicKey;
}> = ({ closeModal, traits, nftMint }) => {
  const [editingTraits, setEditingTraits] = useState(traits);

  const onCloseModal = () => {
    setEditingTraits(traits);
    closeModal();
  };

  const wallet = useAnchorWallet();

  const [error, setError] = useState<string>();

  const toggleSelectedItem = (
    typeName: string,
    valueName: string,
    shouSelect: boolean
  ) => {
    const traitIndex = editingTraits.availableTraits.findIndex(
      (t) => t.name === typeName
    );
    const storedTraits = [...editingTraits.availableTraits];
    const traitValueIndex = storedTraits[traitIndex].values.findIndex(
      (t) => t.value == valueName
    );
    storedTraits[traitIndex].values[traitValueIndex] = {
      ...storedTraits[traitIndex].values[traitValueIndex],
      isSelected: shouSelect,
    };
    setEditingTraits({ ...editingTraits, availableTraits: storedTraits });
  };

  const editTraits = (traitAction: EditTraitAction, traitName: string) => {
    const storedTraits = [...editingTraits.availableTraits];
    const traitType = storedTraits.findIndex((t) => t.name === traitName);
    storedTraits[traitType].values = [...storedTraits[traitType].values].map(
      (v) => {
        return {
          ...v,
          isSelected: false,
          isActive: v.isSelected
            ? traitAction === EditTraitAction.Revoke
            : v.isActive,
          hasChanged: v.isSelected || v.isNew,
        };
      }
    );
    storedTraits[traitType].hasEdited = true;
    setEditingTraits({
      ...editingTraits,
      availableTraits: [...storedTraits],
    });
  };

  const saveNewBatchOfTraits = (
    traits: ITraitConfigValue[],
    traitName: string
  ) => {
    const storedTraits = [...editingTraits.availableTraits];
    const traitIndex = storedTraits.findIndex((t) => t.name === traitName);
    storedTraits[traitIndex].hasEdited = true;
    traits.forEach((t) => {
      storedTraits[traitIndex].values.push(t);
    });

    setEditingTraits({ ...editingTraits, availableTraits: storedTraits });
  };

  const updateTraits = async () => {
    try {
      const updateArgs: CreateTraitConfigArgs[] = [];
      let hasError = false;
      setError(undefined);

      editingTraits.availableTraits.forEach((at) => {
        const newTraits = at.values.filter((tv) => tv.isNew && tv.isActive);
        if (newTraits.length > 0) {
          updateArgs.push({
            action: TraitAction.Add,
            name: at.name,
            values: newTraits.map((nt) => nt.value),
          });
        }
        const removingTraits = at.values.filter(
          (tv) => tv.hasChanged && !tv.isActive && !tv.isNew
        );
        if (removingTraits.length > 7) {
          const chunkedData = chunk(removingTraits, 7);
          chunkedData.forEach((ch: any[]) => {
            updateArgs.push({
              action: TraitAction.Remove,
              name: at.name,
              values: ch.map((nt) => nt.value),
            });
          });
        } else if (removingTraits.length > 0) {
          updateArgs.push({
            action: TraitAction.Remove,
            name: at.name,
            values: removingTraits.map((nt) => nt.value),
          });
        }
        const addingTraits = at.values.filter(
          (tv) => tv.hasChanged && tv.isActive
        );
        if (addingTraits.length > 7) {
          const chunkedData = chunk(addingTraits, 7);
          chunkedData.forEach((ch: any[]) => {
            updateArgs.push({
              action: TraitAction.Modify,
              name: at.name,
              values: ch.map((nt) => nt.value),
            });
          });
        } else if (addingTraits.length > 0) {
          updateArgs.push({
            action: TraitAction.Modify,
            name: at.name,
            values: addingTraits.map((nt) => nt.value),
          });
        }
      });

      if (!hasError)
        await createTraitConfig(
          wallet!,
          updateArgs,
          editingTraits.collection,
          editingTraits.updateAuthoirty,
          nftMint
        );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal closeModal={onCloseModal}>
      <div className="edit-config">
        <div className="edit-config__header">
          <h3 className="edit-config__title">On chain traits</h3>
        </div>
        {editingTraits.availableTraits.map((traitType) => (
          <div className="edit-config__item">
            <div className="edit-config__heading">
              <p className="edit-config__item-key">{traitType.name}</p>
              <ConfigButtons
                traitsCount={traitType.values.length}
                traitName={traitType.name}
                saveTraits={saveNewBatchOfTraits}
                handleClick={(action: EditTraitAction) =>
                  editTraits(action, traitType.name)
                }
              />
            </div>
            <div className="edit-config__item-value-map">
              {traitType.values.map((item) => (
                <EditTraitValue
                  onItemSelected={toggleSelectedItem}
                  value={item.value}
                  isActive={item.isActive ?? false}
                  isSelected={item.isSelected ?? false}
                  isNew={item.isNew ?? false}
                  typeName={traitType.name}
                  valueName={item.value}
                  key={item.value}
                />
              ))}
            </div>
          </div>
        ))}
        {error && <p className="add-new-trait__error">{error}</p>}
        <div className="edit-config__buttons">
          <button onClick={onCloseModal} className="add-new-trait__cancel">
            Cancel
          </button>
          <MainButton title="Update traits" onClick={updateTraits} />
        </div>
      </div>
    </Modal>
  );
};

export default EditConfigModal;
