import { FC, useCallback, useEffect } from "react";
import Modal from "../Modal/Modal";
import TransactionItem from "./TransactionItem/TransactionItem";
import "./BulkTransactionsModal.scss";
import { sleep } from "../../solana/transactions/sendTransactions";
import useTransactionStore from "../../store/transactionStore";
import { TransactionState } from "../../common/common.enums";
import { toggleModalOpenClass } from "../../utiilities/helpers";

const BulkTransactionsModal: FC = () => {
  const {
    transactions,
    isProcessing,
    closeTransactionProcess,
    warningDescription,
    customTitle,
  } = useTransactionStore();

  const closeModalIfNeeded = useCallback(() => {
    if (
      !transactions.find(
        (item) => item.transactionState === TransactionState.Loading
      )
    ) {
      closeTransactionProcess();
      toggleModalOpenClass(false);
    }
  }, [transactions, closeTransactionProcess]);

  useEffect(() => {
    void closeIfAllTransactionPassed();
  }, [transactions]);

  const closeIfAllTransactionPassed = async () => {
    if (
      transactions.filter(
        (item) => item.transactionState === TransactionState.Succeeded
      ).length === transactions.length
    ) {
      await sleep(2000);
      closeTransactionProcess();
    }
  };

  return (
    <>
      {isProcessing && (
        <Modal closeModal={closeModalIfNeeded}>
          <h1 className="bulk-modal__title">{"Transaction list"}</h1>
          {customTitle && (
            <p className="bulk-modal__description">{customTitle}</p>
          )}
          {warningDescription && (
            <div className="bulk-modal__warning">
              <p>{warningDescription}</p>
            </div>
          )}
          <div className="bulk-modal__transactions">
            {transactions.map((item) => (
              <TransactionItem networkItem={item} key={item.number} />
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};

export default BulkTransactionsModal;
