import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { FC, Fragment, useEffect, useState } from "react";
import MainButton from "../../components/MainButton/MainButton";
import { createTraitConfig, getTraitConfigInfo } from "../../solana/traits";
import { MAINNET_RPC_CONNECTION } from "../../solana/utilities";
import {
  getCollectionSlugFromMint,
  getCollectionTraitsFromSlug,
} from "../../api/chain-traits-api";
import CreateConfigModal from "./CreateConfigModal/CreateConfigModal";
import "./HomePage.scss";
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";
import EditConfigModal from "./EditConfigModal/EditConfigModal";
import { ITraitConfig } from "../../common/common.interface";
import {
  arrayToMap,
  mapTraitConfigFromChain,
  validatePublicKeyInput,
} from "../../utiilities/helpers";
import CollectionNfts from "./CollectionNfts/CollectionNfts";
import ConnectWallet from "../../components/ConnectWallet/ConnectWallet";
import { CreateTraitConfigArgs, TraitAction } from "../../generated";
import { EMPTY_STRING } from "../../common/common.constants";

const HomePage: FC = () => {
  const wallet = useAnchorWallet();

  const [collection, setCollection] = useState<string>();
  const [mintInput, setMintInput] = useState<string>();
  const [collectionSlug, setCollectionSlug] = useState<string>();
  const [firstCreator, toggleFirstCreator] = useState(false);
  const [showTraitConfigInfo, toggleTraitConfigInfo] = useState(false);
  const [traitConfig, setTraitConfig] = useState<ITraitConfig>();
  const [showCreateConfigModal, toggleCreateConfigModal] = useState(false);
  const [showEditConfigModal, toggleshowEditConfigModal] = useState(false);
  const [offChainTraits, setOffChainTraits] = useState<Map<string, string[]>>(
    new Map()
  );
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [mintInputError, setMintInputError] = useState(EMPTY_STRING);

  useEffect(() => {
    if (!wallet) {
      setMintInputError(EMPTY_STRING);
      removeCollectionInfo();
    }
  }, [wallet?.publicKey]);

  const createTraitConfigHandler = async () => {
    try {
      if (collection && wallet) {
        const args: CreateTraitConfigArgs[] = [];

        offChainTraits.forEach((values, key) => {
          args.push({
            name: key,
            values: values,
            action: TraitAction.Add,
          });
        });

        await createTraitConfig(
          wallet,
          args,
          new PublicKey(collection),
          wallet?.publicKey,
          firstCreator && mintInput ? new PublicKey(mintInput) : undefined
        );
        const configInfo = await getTraitConfigInfo(new PublicKey(collection));

        setTraitConfig(mapTraitConfigFromChain(configInfo));
        toggleCreateConfigModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTraitConfigInfoFromCollection = async () => {
    try {
      const mintInputErrorMsg = await validatePublicKeyInput(mintInput);
      if (mintInputErrorMsg !== EMPTY_STRING) {
        setMintInputError(mintInputErrorMsg);
        removeCollectionInfo();
        return;
      } else {
        setMintInputError(EMPTY_STRING);
      }

      const mintMetadata = await Metadata.findByMint(
        MAINNET_RPC_CONNECTION,
        new PublicKey(mintInput!)
      );

      //IMPORTANT: UNCOMMENT THIS CODE BEFORE MAINNET, THIS IS COMMENTED ONLY FOR TESTING
      // if (
      //   mintMetadata.data.updateAuthority.toString() !==
      //   wallet?.publicKey.toString()
      // ) {
      //   setNotAuthorized(true);
      // } else {
      //   setNotAuthorized(false);
      // }

      let collectionAddress;
      if (mintMetadata.data.collection) {
        collectionAddress = mintMetadata.data.collection.key;
        toggleFirstCreator(false);
      } else {
        collectionAddress =
          mintMetadata.data.data.creators &&
          mintMetadata.data.data.creators[0].address;
        toggleFirstCreator(true);
      }

      if (!collectionAddress) {
        throw new Error("Can not find collection");
      }

      const slug = await getCollectionSlugFromMint(mintInput!);
      try {
        const configInfo = await getTraitConfigInfo(
          new PublicKey(collectionAddress)
        );
        //THIS IS ONLY FOR TESTING, BECAUSE THIS CHECK WILL BE ALREADY PROCESSED
        if (
          configInfo.updateAuthoirty.toString() !== wallet?.publicKey.toString()
        ) {
          setNotAuthorized(true);
        } else {
          setNotAuthorized(false);
        }
        setTraitConfig(mapTraitConfigFromChain(configInfo));
      } catch (error) {
        const traits = await getCollectionTraitsFromSlug(slug);
        setOffChainTraits(traits);
        setTraitConfig(undefined);
        setNotAuthorized(false);
      }

      setCollectionSlug(slug);
      toggleTraitConfigInfo(true);
      setCollection(collectionAddress.toString());
    } catch (error) {
      console.log(error);
      toggleTraitConfigInfo(false);
    }
  };

  const removeCollectionInfo = () => {
    setCollection(undefined);
    setMintInput(undefined);
    setCollectionSlug(undefined);
    setTraitConfig(undefined);
    toggleTraitConfigInfo(false);
    setNotAuthorized(false);
  };

  return (
    <Fragment>
      {showCreateConfigModal && (
        <CreateConfigModal
          closeModal={() => toggleCreateConfigModal(false)}
          traits={offChainTraits}
          createConfig={createTraitConfigHandler}
        />
      )}
      {showEditConfigModal && traitConfig && (
        <EditConfigModal
          closeModal={() => toggleshowEditConfigModal(false)}
          traits={traitConfig}
          nftMint={
            firstCreator && mintInput ? new PublicKey(mintInput) : undefined
          }
        />
      )}

      <div className="home-page">
        {wallet?.publicKey ? (
          <div>
            <div className="home-page__collection">
              <p className="home-page__collection-title">
                Paste nft mint from your collection
              </p>
              <div className="home-page__collection-find">
                <input
                  className="home-page__collection-input"
                  onChange={(e) => setMintInput(e.target.value)}
                />

                <MainButton
                  onClick={getTraitConfigInfoFromCollection}
                  title="Find collection config"
                />
              </div>
              {mintInputError && (
                <p className="home-page__input-error">{mintInputError}</p>
              )}
            </div>
            {!notAuthorized ? (
              <>
                {showTraitConfigInfo && (
                  <div className="home-page__config-info">
                    <p className="home-page__config-info-title">Config info:</p>
                    <MainButton
                      onClick={() => {
                        !traitConfig
                          ? toggleCreateConfigModal(true)
                          : toggleshowEditConfigModal(true);
                      }}
                      title={
                        traitConfig ? "View config details" : "Create config"
                      }
                    />
                  </div>
                )}
                <CollectionNfts
                  collection={collection}
                  collectionSlug={collectionSlug}
                  traitConfig={traitConfig}
                />
              </>
            ) : (
              <div className="home-page__authority">
                <h4>Sorry, you are not update authority of this collection.</h4>
              </div>
            )}
          </div>
        ) : (
          <ConnectWallet />
        )}
      </div>
    </Fragment>
  );
};

export default HomePage;
