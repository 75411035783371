import { PublicKey } from "@solana/web3.js";
import axios from "axios";
import { EMPTY_STRING } from "../common/common.constants";
import {
  ITraitConfig,
  ITraitConfigTrait,
  ITraitConfigValue,
} from "../common/common.interface";
import { AvailableTrait, TraitConfig } from "../generated";

export const QUICK_NODE_MAINNET_ENDPOINT =
  "https://dry-cosmological-pool.solana-mainnet.quiknode.pro/e2650ac4ff2395af0262c730666ef3b1064037ce/";

export const toggleModalOpenClass = (state: boolean) => {
  document.body.classList.toggle("modal-open", state);
};

export const mapTraitConfigFromChain = (
  traitConfig: TraitConfig
): ITraitConfig => {
  const traits: ITraitConfigTrait[] = [];
  traitConfig.availableTraits.forEach((value, key) => {
    const traitValues: ITraitConfigValue[] = [];

    value.forEach((item, key) => {
      traitValues.push({
        // id: key,
        isActive: item.isActive,
        value: item.value,
      });
    });

    traits.push({
      // id: key.id,
      name: key,
      values: traitValues,
    });
  });
  return {
    availableTraits: traits,
    collection: traitConfig.collection,
    lastModified: Number(traitConfig.lastModified),
    updateAuthoirty: traitConfig.updateAuthoirty,
  };
};

export function getTrimmedPublicKey(publicKey: PublicKey | string): string {
  const publicKeyString = publicKey.toString();
  return (
    publicKeyString.substring(0, 5) +
    "..." +
    publicKeyString.substring(publicKeyString.length - 5)
  );
}

export const generateSkeletonArrays = (quantity: number) => [
  ...Array(quantity).keys(),
];

export const validatePublicKeyInput = async (
  publicKeyAddress: string | undefined
) => {
  try {
    if (!publicKeyAddress) {
      return "Mint can not be empty";
    }
    const publicKey = new PublicKey(publicKeyAddress);
    return EMPTY_STRING;
  } catch (error) {
    return "Not valid address";
  }
};

export const arrayToMap = (values: string[]) => {
  const map = new Map<number, AvailableTrait>();

  values.forEach((v, index) => {
    map.set(index, { isActive: true, value: v });
  });

  return map;
};

export const configValueToMap = (values: ITraitConfigValue[]) => {
  const map = new Map<string, AvailableTrait>();
  values.forEach((v) => {
    map.set(v.value, {
      isActive: v.isActive,
      value: v.value,
    });
  });
  return map;
};

//TESTING DIFFERENT WAYS TO FETCH NFTS - SOLUTION: TENSOR

//Fetching nfts from quick node - slow
export const getNftsWithQuickNode = async (
  firstCreator: PublicKey
): Promise<any[]> => {
  const responseArray: any[] = [];
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const data = {
    jsonrpc: "2.0",
    id: 1,
    method: "qn_fetchNFTsByCreator",
    params: [
      {
        creator: "AxFuniPo7RaDgPH6Gizf4GZmLQFc4M5ipckeeZfkrPNn",
        page: 1,
        perPage: 40,
      },
    ],
  };
  const response = (await axios.post(QUICK_NODE_MAINNET_ENDPOINT, data, config))
    .data;

  console.log(response, "RESPONSE");
  responseArray.push(response);
  const totalPages = response.result.totalPages;
  let pageNumber = response.result.pageNumber;
  while (totalPages > pageNumber) {
    const data = {
      jsonrpc: "2.0",
      id: 1,
      method: "qn_fetchNFTsByCreator",
      params: [
        {
          creator: "AxFuniPo7RaDgPH6Gizf4GZmLQFc4M5ipckeeZfkrPNn",
          page: pageNumber + 1,
          perPage: 40,
        },
      ],
    };
    const response = (
      await axios.post(QUICK_NODE_MAINNET_ENDPOINT, data, config)
    ).data;
    responseArray.push(response);
    pageNumber = response.result.pageNumber;
    console.log(response);
  }
  for (const response of responseArray) {
    console.log(response);
  }

  return responseArray;
};
//Fetching nfts from magic eden
export const fetchMagicEdenNftData = async (nftMint: string) => {
  try {
    const response = (
      await axios.get(`https://api-mainnet.magiceden.dev/v2/tokens/${nftMint}`)
    ).data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

//Fetching nfts from Helius - returns only name and mint address
export const getMintlist = async () => {
  const { data } = await axios.post(
    "https://api.helius.xyz/v1/mintlist?api-key=6cba2a80-0edd-4fef-a317-514332c340e3",
    {
      query: {
        // ABC collection
        firstVerifiedCreators: ["A4FM6h8T5Fmh9z2g3fKUrKfZn6BNFEgByR8QGpdbQhk1"],
      },
      options: {
        limit: 10000,
      },
    }
  );
  console.log("Mintlist: ", data.result);
};
